import React, { useState } from 'react';
import {
	LoadingOverlay,
	Title,
	Text,
	useMantineTheme,
	TextInput,
	Anchor,
	Notification,
} from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import { SubmitStatus } from '../login/Login';
import { getErrorMessage } from '@/utils/utilities';
import { ErrorCode } from '@/configs/errorCode';
import { Button } from '@/components/partials/button/Button';
import { Link } from 'react-router-dom';
import { AppPaths } from '@/configs/appPaths';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import Logo from '@/assets/images/logo.svg?react';

const initialValues = {
	email: '',
};

export const ResetPassword: React.FC = () => {
	const { spacing, other } = useMantineTheme();

	const [status, setStatus] = useState(SubmitStatus.THROTTLE);

	const form = useForm({
		initialValues,
		validate: {
			email: isEmail(getErrorMessage(ErrorCode.INVALID_EMAIL)),
		},
	});

	const handleSubmit = async () => {
		setStatus(SubmitStatus.LOADING);
		const response = await UnauthenticatedApi.requestResetPassword(
			form.values.email
		);
		form.reset();

		if (response === SubmitStatus.SUCCESS) {
			setStatus(SubmitStatus.SUCCESS);
			return;
		}

		form.setFieldError('email', getErrorMessage(ErrorCode.USER_NOT_FOUND));
		setStatus(SubmitStatus.ERROR);
	};

	return (
		<form onSubmit={form.onSubmit(handleSubmit)}>
			<LoadingOverlay visible={status === SubmitStatus.LOADING} />
			<Logo />
			<Title order={1} c="blue.8" my="lg">
				Zapomniałeś hasła?
			</Title>
			<Text size="lg" mb="lg">
				Wpisz swój adres e-mail i zresetuj swoje hasło.
			</Text>
			{status === SubmitStatus.ERROR && (
				<Notification {...other.notificationProps.danger} mb="lg">
					<Text fw={500}>{getErrorMessage(ErrorCode.USER_NOT_FOUND)}</Text>
				</Notification>
			)}
			{status === SubmitStatus.SUCCESS && (
				<Notification {...other.notificationProps.success} mb="lg">
					<Text fw={500}>
						Na podany adres e-mail wysłano link do zresetowania hasła.
					</Text>
				</Notification>
			)}
			<TextInput
				{...form.getInputProps('email')}
				required
				size="lg"
				type="email"
				mb="lg"
				label="Adres e-mail"
				placeholder="E-mail"
			/>

			<Button
				themes={['primary', 'fullWidth']}
				buttonProps={{
					type: 'submit',
					style: {
						marginBottom: spacing.lg,
					},
				}}
			>
				Chcę otrzymać link do resetu hasła
			</Button>
			<Text size="lg">
				Wróć do ekranu{' '}
				<Anchor
					c="black"
					fw={600}
					component={Link}
					to={`/${AppPaths.LOGIN}`}
					underline="hover"
				>
					logowania.
				</Anchor>
			</Text>
		</form>
	);
};
