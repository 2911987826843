import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/store/index';
import { StoreKey } from '@/configs/storeKeys';
import { UserVoucherPassenger } from '@/api/UserVoucherPassengerApi';
import { Partner } from '@/api/PartnerApi';
import { User } from '@/types/user';
import { getEmptyPassenger } from '@/utils/utilities';
import { Location } from '@/api/LocationApi';
import { Gender, UUID } from '@/types/types';
import APP_CONFIG from '@/configs/appConfig';

export enum UserVoucherStep {
	VOUCHER = 'voucher',
	PASSANGERS = 'passengers',
	LOCATION = 'location',
}

export type UserVoucherFormFile = { data: string } & Pick<
	File,
	'name' | 'type'
>;

export interface UserVoucherForm {
	voucherCode: string;
	expirationDate: number | null;
	image: UserVoucherFormFile | null;
	locations: Location[];
	partner: Partner | null;
	user: User | null;
	passengersAmount: number;
	passengers: UserVoucherPassenger[];
	contactUser: {
		firstName: string;
		lastName: string;
		email: string;
		phoneNumber: string;
		gender: Gender;
	};
}

export interface UserVoucherOptions {
	currentStep: UserVoucherStep;
	skipPassangersForm: boolean;
	termsAccepted: boolean;
	newsletterAccepted: boolean;
	enabledSteps: UserVoucherStep[];
	isContactUserPassenger: boolean;
}

interface SliceState {
	voucher: UserVoucherForm;
	options: UserVoucherOptions;
}

const initialState: SliceState = {
	voucher: {
		voucherCode: '',
		expirationDate: null,
		image: null,
		locations: [],
		partner: null,
		user: null,
		passengersAmount: 1,
		passengers: [getEmptyPassenger()],
		contactUser: {
			firstName: '',
			lastName: '',
			email: '',
			phoneNumber: '',
			gender: Gender.FEMALE,
		},
	},
	options: {
		currentStep: UserVoucherStep.VOUCHER,
		skipPassangersForm: false,
		termsAccepted: false,
		newsletterAccepted: false,
		enabledSteps: [UserVoucherStep.VOUCHER],
		isContactUserPassenger: false,
	},
};

export const userVoucherSlice = createSlice({
	name: StoreKey.USER_VOUCHER,
	initialState,
	reducers: {
		updateVoucher: (state, action: PayloadAction<Partial<UserVoucherForm>>) => {
			Object.assign(state.voucher, action.payload);
		},
		updateOptions: (
			state,
			action: PayloadAction<Partial<UserVoucherOptions>>
		) => {
			Object.assign(state.options, action.payload);
		},
		updateContactUser: (
			state,
			action: PayloadAction<Partial<UserVoucherForm['contactUser']>>
		) => {
			Object.assign(state.voucher.contactUser, action.payload);
		},
		changePassengersAmount: (state, action: PayloadAction<number>) => {
			if (action.payload > APP_CONFIG.MAX_PASSENGERS_AMOUNT) return;

			state.voucher.passengersAmount = action.payload;

			if (action.payload > state.voucher.passengers.length) {
				for (let i = state.voucher.passengers.length; i < action.payload; i++) {
					state.voucher.passengers.push(getEmptyPassenger());
				}
			} else {
				state.voucher.passengers = state.voucher.passengers.slice(
					0,
					action.payload
				);
			}
		},
		editPassenger: (
			state,
			{
				payload,
			}: PayloadAction<{ id: UUID; data: Partial<UserVoucherPassenger> }>
		) => {
			for (const passenger of state.voucher.passengers) {
				if (passenger.id === payload.id) {
					Object.assign(passenger, payload.data);
					break;
				}
			}
		},
		setUserVoucherStep: (state, action: PayloadAction<UserVoucherStep>) => {
			state.options.currentStep = action.payload;
		},
		addEnabledStep: (state, action: PayloadAction<UserVoucherStep>) => {
			state.options.enabledSteps.push(action.payload);
		},
		removeEnabledStep: (state, action: PayloadAction<UserVoucherStep>) => {
			state.options.enabledSteps = state.options.enabledSteps.filter(
				(step) => step !== action.payload
			);
		},
		resetUserVoucherState: (state) => {
			Object.assign(state, initialState);
		},
	},
});

export const {
	updateVoucher,
	updateOptions,
	updateContactUser,
	changePassengersAmount,
	editPassenger,
	setUserVoucherStep,
	addEnabledStep,
	removeEnabledStep,
	resetUserVoucherState,
} = userVoucherSlice.actions;

export const selectUserVoucherState = (state: RootState) =>
	state[StoreKey.USER_VOUCHER];
export default userVoucherSlice.reducer;
