import { QueryKey } from '@/configs/queryKeys';
import { QueryFunctionContext } from '@tanstack/react-query';
import { omitBy, trim } from 'lodash';
import {
	ApiFormattedResponseData,
	ApiPaginatedResponse,
	ApiQueryOptions,
	UUID,
} from '@/types/types';
import httpClient from './httpClient';

export interface BaseEntity {
	id: UUID;
}

abstract class EntityApi<TData extends BaseEntity> {
	readonly queryKey: QueryKey;

	constructor(queryKey: QueryKey) {
		this.queryKey = queryKey;
		this.update = this.update.bind(this);
		this.create = this.create.bind(this);
		this.delete = this.delete.bind(this);
	}

	async getAll({
		queryKey,
	}: QueryFunctionContext<
		[
			QueryKey,
			Partial<
				Pick<ApiQueryOptions<TData>, 'search' | 'searchFields' | 'sort'>
			>?,
			Record<string, any>?
		]
	>) {
		const params = omitBy(
			{
				search: trim(queryKey[1]?.search),
				searchFields: queryKey[1]?.searchFields?.join(','),
				[`order[${String(queryKey[1]?.sort?.key)}]`]: queryKey[1]?.sort?.order,
				...queryKey[2],
			},
			(value) => !value
		);
		Object.assign(params, { limit: 0, ...queryKey[2] });

		const response = await httpClient.get<ApiPaginatedResponse<TData>>(
			queryKey[0],
			{
				params,
			}
		);
		return response.data.data;
	}

	async getPaginated({
		queryKey,
	}: QueryFunctionContext<[QueryKey, ApiQueryOptions<TData>]>): Promise<
		ApiFormattedResponseData<TData>
	> {
		const response = await httpClient.get<ApiPaginatedResponse<TData>>(
			queryKey[0],
			{
				params: {
					page: queryKey[1].page,
					sizePerPage: queryKey[1].sizePerPage,
					[`order[${String(queryKey[1].sort.key)}]`]: queryKey[1].sort?.order,
					search: trim(queryKey[1].search),
					searchFields: queryKey[1].searchFields.join(','),
				},
			}
		);
		return {
			data: response.data.data,
			totalPages: response.data.totalPages,
		};
	}

	async getOne({ queryKey }: QueryFunctionContext<[QueryKey, UUID]>) {
		const response = await httpClient.get<TData>(
			`${queryKey[0]}/${queryKey[1]}`
		);
		return response.data;
	}

	async update(payload: Partial<TData>) {
		const response = await httpClient.patch<TData>(
			`${this.queryKey}/${payload.id}`,
			payload
		);
		return response.data;
	}

	async create(payload: Partial<TData>) {
		const response = await httpClient.post<TData>(this.queryKey, payload);
		return response.data;
	}

	async delete(id: UUID) {
		await httpClient.delete(`${this.queryKey}/${id}`);
	}
}

export default EntityApi;
