import React, { ChangeEventHandler, useEffect, useLayoutEffect } from 'react';
import {
	Box,
	Title,
	Switch,
	Collapse,
	Notification,
	useMantineTheme,
	Text,
} from '@mantine/core';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import {
	UserVoucherStep,
	addEnabledStep,
	editPassenger,
	removeEnabledStep,
	setUserVoucherStep,
	updateOptions,
} from '@/store/slices/userVoucherSlice';
import {
	EditPassengerForm,
	PassengerForm,
} from '@/components/partials/editPassengerForm/EditPassengerForm';
import { isPassengerValid, sanitizePhoneNumber } from '@/utils/utilities';
import { UUID } from '@/types/types';
import { useAuth } from '@/providers/AuthProvider';
import { UnauthContactPassangerSection } from './partials/UnauthContactPassangerSection';

export const UserVoucherPassengers: React.FC = () => {
	const dispatch = useTypedDispatch();

	const user = useAuth().user;

	const { other } = useMantineTheme();

	const { voucher, options } = useTypedSelector((state) => state.userVoucher);

	useLayoutEffect(() => {
		dispatch(setUserVoucherStep(UserVoucherStep.PASSANGERS));
	}, []);

	const handlePassengerChange =
		(id: UUID) => (formPassenger: PassengerForm) => {
			dispatch(
				editPassenger({
					id,
					data: {
						...formPassenger,
						id,
						birthDate: formPassenger.birthDate
							? formPassenger.birthDate.toISOString()
							: null,
						gender: Number(formPassenger.gender),
						phoneNumber: sanitizePhoneNumber(formPassenger.phoneNumber),
					},
				})
			);
		};

	useEffect(() => {
		const isNextEnabled = options.enabledSteps.includes(
			UserVoucherStep.LOCATION
		);
		let shouldNextBeEnabled =
			options.skipPassangersForm ||
			voucher.passengers.every((passenger) => isPassengerValid(passenger));

		if (!user)
			shouldNextBeEnabled = Object.values(voucher.contactUser).every(Boolean);

		if (isNextEnabled && !shouldNextBeEnabled)
			dispatch(removeEnabledStep(UserVoucherStep.LOCATION));

		if (!isNextEnabled && shouldNextBeEnabled)
			dispatch(addEnabledStep(UserVoucherStep.LOCATION));
	}, [voucher.passengers, options.skipPassangersForm, voucher.contactUser]);

	const handleSkipPassangersFormChange: ChangeEventHandler<
		HTMLInputElement
	> = ({ target }) => {
		dispatch(updateOptions({ skipPassangersForm: !target.checked }));
	};

	return (
		<Box>
			<UnauthContactPassangerSection />
			<Switch
				size="md"
				mb="lg"
				checked={!options.skipPassangersForm}
				onChange={handleSkipPassangersFormChange}
				label={
					options.skipPassangersForm
						? 'Uzupełnię dane pasażerów później'
						: 'Uzupełnię dane pasażerów teraz'
				}
			/>
			<Collapse in={!options.skipPassangersForm}>
				{voucher.passengers.map((passenger, idx) => (
					<Box mb={40} key={passenger.id}>
						<Title order={2} c="blue.7" mb="lg">
							Dane pasażera {idx + 1}:
						</Title>
						<EditPassengerForm
							passenger={passenger}
							handleChange={handlePassengerChange(passenger.id)}
							forceSync
						/>
					</Box>
				))}
			</Collapse>
			{options.skipPassangersForm && (
				<Notification {...other.notificationProps.info} mb="lg">
					<Text fw={500}>
						Pamiętaj, aby zająć miejsce w balonowym koszu, należy podać dane
						wszystkich pasażerów. Możesz to zrobić od razu lub w innej wolnej
						chwili, ale nie później niż przy zapisywaniu się na konkretny lot.
					</Text>
				</Notification>
			)}
		</Box>
	);
};
