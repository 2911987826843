import {
	ApiError,
	ApiErrorMessage,
	AppError,
	Gender,
	StringSignature,
} from '@/types/types';
import { UserVoucher, UserVoucherStatus } from '@/api/UserVoucherApi';
import { Locale, formatDistance } from 'date-fns';
import { validatePolish } from 'validate-polish';
import { flatten } from 'lodash';
import { isAxiosError } from 'axios';
import { DateValue } from '@mantine/dates';
import { ErrorCode } from '@/configs/errorCode';
import { FileWithPath } from '@mantine/dropzone';
import { UserVoucherPassenger } from '@/api/UserVoucherPassengerApi';
import { VoucherPermission } from '@/api/VoucherApi';
import { OrderStatus } from '@/api/OrderApi';
import { Flight, FlightStatus, FlightTypeFilter } from '@/api/FlightApi';
import { isEmail } from '@mantine/form';
import { ShippingMethod } from '@/api/ShippingMethodApi';
import { FlightPassengerStatus } from '@/api/FlightPassengerApi';
import { FlightNotificationPreference } from '@/api/FlightNotificationApi';
import APP_CONFIG from '@/configs/appConfig';
import moment from 'moment';

export function formatDateForAPI(date: moment.MomentInput, format?: string) {
	return moment(date, format).format('YYYY-MM-DD');
}

export function formatDateForPresentation(date: moment.MomentInput) {
	if (!date) return '-';
	return moment(date).format('DD.MM.YYYY');
}

export function formatTimeForPresentation(
	date: moment.MomentInput | string = new Date()
) {
	return moment(date).format('HH:mm');
}

export function formatExactDate(date: moment.MomentInput = new Date()) {
	return `${formatDateForPresentation(date)}, ${formatTimeForPresentation(
		date
	)}`;
}

const PASSWORD_REGEX = /^(?=\D*\d)[^ ]{6,}$/;

/**
 *
 * @param password one number and at least 8
 * @returns
 */
export function validatePassword(password: string) {
	return PASSWORD_REGEX.test(password);
}

const PHONE_REGEX =
	/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;

/**
 *
 * @param phoneNumber validate international
 * @returns
 */
export function validatePhoneNumber(phoneNumber: string) {
	return PHONE_REGEX.test(phoneNumber) && phoneNumber.length >= 9;
}

export function sanitizePhoneNumber(phoneNumber: string) {
	return phoneNumber.replace(/\D/g, '');
}

/**
 *
 * @param accountNumber validate polish
 * @returns
 */
export function validateAccountNumber(accountNumber: string) {
	const trimmed = accountNumber.trim();
	return /\d{26}/.test(trimmed);
}

/**
 *
 * @param postalCode validate polish
 * @returns
 */
export function validatePostalCode(postalCode: string) {
	const trimmed = postalCode.trim();
	return /^[0-9]{2}-[0-9]{3}/.test(trimmed);
}

export function validatePesel(pesel: string) {
	const trimmed = pesel.trim();
	return validatePolish.pesel(trimmed);
}

/**
 *
 * @param vatNumber validate polish
 * @returns
 */
export function validateVatNumber(vatNumber: string) {
	const trimmed = vatNumber.trim();
	return validatePolish.nip(trimmed);
}

export function removeWhiteSpaces(string: string) {
	return string.replace(/\s/g, '');
}

export function isValidDateObj(date: moment.MomentInput) {
	return moment(date).isValid();
}

export function incrementDate(date: moment.MomentInput) {
	return moment(date).add(1, 'day').toDate();
}

export function sortByDate(
	dateA: moment.MomentInput,
	dateB: moment.MomentInput,
	order: 'asc' | 'desc' = 'asc'
) {
	const first = moment(dateA).valueOf(),
		second = moment(dateB).valueOf();

	return order === 'asc' ? first - second : second - first;
}

export function handleUnload(e: BeforeUnloadEvent) {
	e.preventDefault();
	e.returnValue = '';
}

export const ID_REGEX =
	/[a-f0-9]{8}-?[a-f0-9]{4}-?[a-f0-9]{4}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/g;

export function getIdsFromText(text: string) {
	return [...new Set(flatten([...text.matchAll(ID_REGEX)]))];
}

export function fileTypeToHeader(fileType: 'csv' | 'xlsx' | 'pdf') {
	switch (fileType) {
		case 'xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		case 'csv':
			return 'text/csv';
		case 'pdf':
			return 'application/pdf';
		default:
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	}
}

export function getContent(conent: StringSignature, slug: string) {
	return conent[slug];
}

export function formatDateFromNow(date: Date, locale: Locale) {
	return formatDistance(date, new Date(), {
		locale,
		includeSeconds: true,
		addSuffix: true,
	});
}

export const formatPrice = (value: number, opts?: Intl.NumberFormatOptions) => {
	const formatter = new Intl.NumberFormat(APP_CONFIG.APP_LANGUAGE, {
		style: 'currency',
		currency: 'PLN',
		currencyDisplay: 'symbol',
		maximumFractionDigits: !value ? 0 : undefined,
		...opts,
	});

	return formatter.format(value);
};

export const isApiError = (error: unknown): error is ApiError => {
	return (
		!!(error as AppError).status &&
		!!(error as AppError).stack &&
		!!(error as ApiError).message
	);
};

export const isAppError = (error: unknown): error is AppError => {
	return (
		!!(error as AppError).status &&
		!!(error as AppError).stack &&
		!!(error as AppError).message
	);
};

export const getErrorCodes = (error: unknown) => {
	if (isAxiosError<ApiError>(error) && error.response)
		return Array.isArray(error.response.data.message)
			? error.response.data.message
			: [error.response.data.message];

	return [ErrorCode.GENERIC];
};

export const errorCodesToMessage = (errorCodes: ErrorCode[]) =>
	errorCodes.map((code) => `errors.${code}`).join(', ');

export function getFileSrc(path?: string) {
	return `${import.meta.env.VITE_API_URL_UPLOADS}${path}`;
}

export function formatDateForApi(date: DateValue) {
	if (!date) return null;

	return moment(date).format('YYYY-MM-DD');
}

export function natSort(nameA = '', nameB = '', order: 'asc' | 'desc' = 'asc') {
	let a: string | number = Number(nameA),
		b: string | number = Number(nameB);

	if (isNaN(a) || isNaN(b)) {
		return order === 'asc'
			? nameA.localeCompare(nameB)
			: nameB.localeCompare(nameA);
	}

	return order === 'asc' ? a - b : b - a;
}

export function inputWidthValue(value: string) {
	return `${value.length + 3}ch`;
}

export function mapIndexToPolishText(idx: number) {
	return [
		'pierwszą',
		'drugą',
		'trzecią',
		'czwartą',
		'piątą',
		'szóstą',
		'siódmą',
	][idx];
}

export function getFlightStatus(flightStatus: FlightStatus) {
	const data = { color: '', name: '' };

	switch (flightStatus) {
		case FlightStatus.DISABLED:
			data.color = 'dark.3';
			data.name = 'Lot niedostępny';
			break;
		case FlightStatus.PLANNED:
			data.color = 'blue.6';
			data.name = 'Zaplanowany';
			break;
		case FlightStatus.CANCELLED:
			data.color = 'red.5';
			data.name = 'Lot odwołany';
			break;
		case FlightStatus.FINISHED:
			data.color = 'green.6';
			data.name = 'Lot zakończony';
			break;
		case FlightStatus.ACCEPTED:
			data.color = 'green.6';
			data.name = 'Lot potwierdzony';
			break;
	}

	return data;
}

export function getFlightPassengerStatus(flightStatus: FlightPassengerStatus) {
	const data = { color: '', name: '' };

	switch (flightStatus) {
		case FlightPassengerStatus.REGISTERED:
			data.color = 'blue.6';
			data.name = 'Oczekuje na potwierdzenie';
			break;
		case FlightPassengerStatus.ACCEPTED:
			data.color = 'green.6';
			data.name = 'Zakwalifikowany na lot';
			break;
		case FlightPassengerStatus.REJECTED:
			data.color = 'red.5';
			data.name = 'Odrzucony';
			break;
		case FlightPassengerStatus.RESIGNED:
			data.color = 'red.6';
			data.name = 'Rezygnacja klienta z terminu';
			break;
		case FlightPassengerStatus.ACCEPTED_TO_ANOTHER_FLIGHT:
			data.color = 'dark.6';
			data.name = 'Zakwalifikowany na inny termin';
			break;
		case FlightPassengerStatus.WANTS_TO_SIGN_OFF:
			data.color = 'red.5';
			data.name = 'Zgłoszono chęć rezygnacji';
			break;
		case FlightPassengerStatus.SUSPENDED:
			data.color = 'yellow.6';
			data.name = 'Zawieszony';
			break;
	}

	return data;
}

export function fileToBase64(file: File | FileWithPath) {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result as string);
		reader.onerror = reject;
		reader.readAsDataURL(file);
	});
}

export function base64ToFile(
	base64String: string,
	fileName = '',
	fileType = 'image/jpeg'
) {
	const byteCharacters = atob(base64String.split(',')[1]);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const blob = new Blob([byteArray], { type: 'application/octet-stream' });
	return new File([blob], fileName, { type: fileType });
}

export function getEmptyPassenger(): UserVoucherPassenger {
	return {
		id: crypto.randomUUID(),
		firstName: '',
		lastName: '',
		gender: Gender.FEMALE,
		weight: 75,
		birthDate: null,
		city: '',
		postalCode: '',
		voivodeship: '',
		email: '',
		phoneNumber: '',
		isNew: true,
	};
}

export function isPassengerValid(passenger: UserVoucherPassenger) {
	if (isEmail()(passenger.email)) return false;

	let key: keyof UserVoucherPassenger;
	for (key in passenger) {
		if (['gender', 'isNew', 'email'].includes(key)) continue;
		if (!passenger[key]) return false;
	}

	return true;
}

export function getErrorContent(code: ErrorCode) {
	switch (code) {
		case ErrorCode.DIFFERENT_PASSWORDS:
			return 'Oba hasła muszą być takie same!';
		case ErrorCode.DIFFERENT_EMAILS:
			return 'Oba adresy e-mail muszą być takie same!';
		case ErrorCode.EMAIL_NOT_CHANGED:
			return 'Nowy adres e-mail musi być inny niż aktualny!';
		case ErrorCode.USER_NOT_FOUND:
			return 'Taki email nie istnieje w systemie';
		case ErrorCode.INVALID_EMAIL:
			return 'Podany adres email jest nieprawidłowy!';
		case ErrorCode.DUPLICATED_EMAIL:
			return 'Podany adres email został już wykorzystany';
		case ErrorCode.INVALID_PASSWORD:
			return 'Podane hasło jest nieprawidłowe!';
		case ErrorCode.PASSWORD_TOO_WEAK:
			return 'Hasło musi mieć co najmniej 8 znaków, małą i wielką literę, cyfrę oraz znak specjalny';
		case ErrorCode.CODE_NOT_FOUND:
			return 'Nie znaleziono takiego kodu!';
		case ErrorCode.CODE_EXCEEDED_USAGE:
			return 'Wykorzystano już maksymalną liczbę użyć tego kodu';
		case ErrorCode.CODE_MISSING_PRODUCTS:
			return 'Ten kod dotyczy innych produktów';
		case ErrorCode.CODE_ALREADY_ADDED:
			return 'Ten kod został już dodany';
		case ErrorCode.CODE_CANNOT_COMBINE:
			return 'Ten kod nie może być użyty z innymi kodami';
		case ErrorCode.CODE_EXPIRED:
			return 'Ten kod wygasł';
		case ErrorCode.SIGNOFF_NOT_ALLOWED:
			return `Przykro nam, wypisać się z lotu można maksymalnie na ${APP_CONFIG.FLIGHT_ALLOWED_HOURS_FOR_SIGN_OUT} godzin przed godziną spotkania.`;

		default:
			return 'Coś poszło nie tak! Spróbuj ponownie później';
	}
}

export function getErrorMessage(codes: ApiErrorMessage) {
	const codeToMessage = (code: ErrorCode | string) =>
		Object.values(ErrorCode).includes(code as ErrorCode)
			? getErrorContent(code as ErrorCode)
			: '';

	const errorMessages = Array.isArray(codes)
		? codes.map((code) => codeToMessage(code))
		: [codeToMessage(codes)];

	const message =
		errorMessages.filter((m) => !!m).join('. ') ||
		codeToMessage(ErrorCode.GENERIC);

	return message;
}

export function mapVoucherPermisionName(permision: VoucherPermission) {
	switch (permision) {
		case VoucherPermission.weekdayAfternoon:
			return 'Wieczór w tygodniu';
		case VoucherPermission.weekdayMorning:
			return 'Poranek w tygodniu';
		case VoucherPermission.weekend:
			return 'Weekendy (rano i wieczorem)';
	}
}

export function getContentForOrderStatus(orderStatus: OrderStatus) {
	let color: string, text: string;

	switch (orderStatus) {
		case OrderStatus.PENDING:
			color = 'blue.6';
			text = 'Oczekująca';
			break;
		case OrderStatus.ACCEPTED:
			color = 'green.6';
			text = 'Zrealizowana';
			break;
		case OrderStatus.REJECTED:
			color = 'red.5';
			text = 'Odrzucona';
			break;
		case OrderStatus.CONFIRMED_ADVANCE:
			color = 'yellow.6';
			text = 'Zaliczka opłacona';
			break;
	}

	return { color, text };
}

export function mapUserVoucherStatusColor(status: UserVoucherStatus) {
	return ['blue.6', 'green.6', 'red.5', 'yellow'][status];
}

export function mapUserVoucherStatusName(status: UserVoucherStatus) {
	switch (status) {
		case UserVoucherStatus.PENDING:
			return 'Oczekujący na zatwierdzenie';
		case UserVoucherStatus.ACCEPTED:
			return 'Aktywny';
		case UserVoucherStatus.USED:
			return 'Wykorzystany';
		case UserVoucherStatus.MISSING_DATA:
			return 'Oczekuje na uzupełnienie';
		case UserVoucherStatus.DISABLED:
			return 'Nieaktywny';
	}
}

export function getFlightAvailability(
	flight: Flight,
	userVouchers: UserVoucher[]
) {
	const flightPassenger = flight.flightPassenger;

	const isRegistered =
			!!flightPassenger &&
			flightPassenger.status === FlightPassengerStatus.REGISTERED,
		isRejected =
			!!flightPassenger &&
			flightPassenger.status === FlightPassengerStatus.REJECTED,
		isAccepted =
			!!flightPassenger &&
			flightPassenger.status === FlightPassengerStatus.ACCEPTED;

	const isUserVouchersEmpty = !userVouchers.length;

	const availableUserVouchers = userVouchers.filter((userVoucher) => {
		if (userVoucher.status !== UserVoucherStatus.ACCEPTED) return false;
		if (!userVoucher.voucher) return false;

		return flight.vouchers.some(
			(voucher) => voucher.id === userVoucher.voucher!.id
		);
	});

	const isClosed = flight.currentCapacity >= flight.maxCapacity;

	return {
		isRegistered,
		isRejected,
		isAccepted,
		isUserVouchersEmpty,
		isClosed,
		availableUserVouchers,
	};
}

export function getMaxAvailableDate() {
	return moment().subtract(APP_CONFIG.PASSANGER_MIN_AGE, 'years').toDate();
}

export const formatFullName = (firstName?: string, lastName?: string) => {
	if (firstName && lastName) return `${firstName} ${lastName}`;
	if (!firstName && !lastName) return `-`;
	return firstName || lastName;
};

export function formatFlightName(locationName: string) {
	return `Lokalizacja: ${locationName}`;
}

export function formatShippingMethodName(shippingMethod: ShippingMethod) {
	const name = shippingMethod.name;
	if (shippingMethod.price)
		return `${name}  (+${formatPrice(shippingMethod.price / 100)})`;

	return name;
}

export function formatPostalCode(postalCode: string) {
	if (postalCode.length < 2) return postalCode;
	if (postalCode.length === 2) return `${postalCode}-`;
	if (postalCode.length > 6) return postalCode.slice(0, 6);
	return postalCode;
}

export function getAmOrPm(date: string) {
	const hour = moment(date).hour();
	return hour < 12 ? 'rano' : 'popołudnie';
}

export function checkIfCanSignOff(flight: Flight) {
	const flightDate = moment(flight.startDate);
	const diff = flightDate.diff(moment(), 'hours');

	return diff >= APP_CONFIG.FLIGHT_ALLOWED_HOURS_FOR_SIGN_OUT;
}

export function mapFlightNotificationPreferenceName(
	preference: FlightNotificationPreference
) {
	switch (preference) {
		case FlightNotificationPreference.ALL:
			return 'Wszystkie loty';
		case FlightNotificationPreference.USER_VOUCHER_PERMITTED:
			return 'Zgodnie z uprawnieniami vouchera';
	}
}

export function reduceUserVouchersData(
	userVouchers: UserVoucher[],
	key: keyof UserVoucher = 'voucherNumber'
) {
	return userVouchers.reduce((acc, curr, idx, arr) => {
		const str =
			acc +
			(key === 'expirationDate'
				? formatDateForPresentation(curr[key])
				: curr[key] || '-');
		if (idx < arr.length - 1) return str + ', ';

		return str;
	}, '');
}

export function mapFlightTypeToName(type: FlightTypeFilter) {
	switch (type) {
		case FlightTypeFilter.MAIN:
			return 'Loty główne';
		case FlightTypeFilter.RESERVE:
			return 'Loty rezerwowe';
		case FlightTypeFilter.ALL:
			return 'Wszystkie loty';
	}
}
