import {
	LoginValues,
	SubmitStatus,
} from '@/components/views/unauthenticated/login/Login';
import { User } from '@/types/user';
import { getErrorCodes } from '@/utils/utilities';
import { HttpStatusCode } from 'axios';
import httpClient from './httpClient';

type TokenResponse = {
	accessToken: string;
	refreshToken: string;
};

abstract class UnauthenticatedApi {
	static login = async ({ email, password }: LoginValues) => {
		const response = await httpClient.post<TokenResponse>('auth/login', {
			email,
			password,
		});

		return response;
	};

	static refreshToken = async (refreshToken: string) => {
		const response = await httpClient.post<TokenResponse>('auth/refresh', {
			refreshToken,
		});
		return response;
	};

	static verifyEmailToken = async (token: string) => {
		try {
			const response = await httpClient.post('users/verify', {
				token,
			});
			return response;
		} catch (error) {
			const message = getErrorCodes(error);
			return message;
		}
	};

	static checkIfEmailAvailable = async (email: string) => {
		try {
			const response = await httpClient.post('auth/register/check', { email });
			return response.status === HttpStatusCode.Created;
		} catch (error) {
			console.error(error);
			return false;
		}
	};

	static register = async (user: Partial<User>) => {
		try {
			const response = await httpClient.post<User>('auth/register', user);
			return response.data;
		} catch (error) {
			const message = getErrorCodes(error);
			return { message };
		}
	};

	static requestResetPassword = async (email: string) => {
		try {
			await httpClient.post('auth/reset_password/create', {
				email,
				redirectUrl: `${import.meta.env.VITE_APP_URL}set-password`,
			});
			return SubmitStatus.SUCCESS;
		} catch (error) {
			const message = getErrorCodes(error);
			return { message };
		}
	};

	static resetPassword = async (hash: string, password: string) => {
		try {
			await httpClient.post('auth/reset_password/process', {
				hash,
				password,
			});
			return SubmitStatus.SUCCESS;
		} catch (error) {
			const message = getErrorCodes(error);

			return { message };
		}
	};
}

export default UnauthenticatedApi;
