import { ChangeEventHandler, FC, useEffect } from 'react';
import {
	UserVoucherForm,
	editPassenger,
	updateContactUser,
	updateOptions,
} from '@/store/slices/userVoucherSlice';
import { Box, Title, Flex, TextInput, Checkbox, Chip } from '@mantine/core';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { useAuth } from '@/providers/AuthProvider';
import { Gender } from '@/types/types';
import { UserVoucherPassenger } from '@/api/UserVoucherPassengerApi';

export const UnauthContactPassangerSection: FC = () => {
	const dispatch = useTypedDispatch();

	const { user } = useAuth();

	const { voucher, options } = useTypedSelector((state) => state.userVoucher);

	const updateVoucher = (data: Partial<UserVoucherForm['contactUser']>) => {
		dispatch(updateContactUser(data));
	};

	const updatePassenger = (data: Partial<UserVoucherPassenger>) => {
		dispatch(
			editPassenger({
				id: voucher.passengers[0].id,
				data,
			})
		);
	};

	const handleVoucherChange: ChangeEventHandler<HTMLInputElement> = ({
		target,
	}) => {
		updateVoucher({ [target.name]: target.value });

		if (options.isContactUserPassenger)
			updatePassenger({ [target.name]: target.value });
	};
	const handleCheckboxChange: ChangeEventHandler<HTMLInputElement> = ({
		target,
	}) => {
		dispatch(updateOptions({ isContactUserPassenger: target.checked }));
	};

	useEffect(() => {
		if (!options.isContactUserPassenger) return;
		updatePassenger(voucher.contactUser);
	}, [options.isContactUserPassenger]);

	if (user) return null;

	return (
		<Box mb="xl">
			<Title order={2} c="blue.7" mb="lg">
				Osoba do kontaktu
			</Title>
			<Flex
				gap="lg"
				mb="lg"
				direction={{
					base: 'column-reverse',
					sm: 'row',
				}}
			>
				<TextInput
					required
					label="Imie"
					placeholder="Imię"
					size="lg"
					className="input-col"
					name="firstName"
					value={voucher.contactUser.firstName}
					onChange={handleVoucherChange}
				/>
				<TextInput
					required
					label="Nazwisko"
					placeholder="Nazwisko"
					size="lg"
					className="input-col"
					name="lastName"
					value={voucher.contactUser.lastName}
					onChange={handleVoucherChange}
				/>
			</Flex>
			<Flex
				gap="lg"
				mb="lg"
				direction={{
					base: 'column-reverse',
					sm: 'row',
				}}
			>
				<TextInput
					required
					label="Adres e-mail"
					placeholder="Adres e-mail"
					type="email"
					size="lg"
					className="input-col"
					name="email"
					value={voucher.contactUser.email}
					onChange={handleVoucherChange}
				/>
				<TextInput
					required
					size="lg"
					label="Numer telefonu"
					placeholder="--- ---- ----"
					type="tel"
					leftSectionWidth={60}
					className="input-col"
					name="phoneNumber"
					value={voucher.contactUser.phoneNumber}
					onChange={handleVoucherChange}
				/>
			</Flex>
			<Box mb="lg">
				<label className="label">Płeć</label>
				<Chip.Group
					multiple={false}
					value={voucher.contactUser.gender.toString()}
					onChange={(val) => {
						const payload = { gender: Number(val) };
						updateVoucher(payload);
						if (options.isContactUserPassenger) updatePassenger(payload);
					}}
				>
					<Flex gap={12}>
						<Chip value={Gender.FEMALE.toString()}>Kobieta</Chip>
						<Chip value={Gender.MALE.toString()}>Mężczyzna</Chip>
						<Chip value={Gender.OTHER.toString()}>Inna</Chip>
					</Flex>
				</Chip.Group>
			</Box>
			<Checkbox
				label="Jestem jednym z pasażerów"
				onChange={handleCheckboxChange}
				checked={options.isContactUserPassenger}
				mb="lg"
			/>
		</Box>
	);
};
