import {
	AnchorHTMLAttributes,
	ButtonHTMLAttributes,
	forwardRef,
	ReactNode,
} from 'react';
import {
	Anchor,
	AnchorProps,
	Box,
	BoxProps,
	Tooltip,
	TooltipProps,
	UnstyledButton,
	UnstyledButtonProps,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import ArrowNextIcon from '@/assets/icons/arrow-circle-right.svg?react';
import ArrowPrevIcon from '@/assets/icons/arrow-circle-left.svg?react';
import classNames from 'classnames';
import styles from './Button.module.scss';

interface BaseProps {
	themes: (
		| 'primary'
		| 'secondary'
		| 'success'
		| 'warning'
		| 'danger'
		| 'arrowNext'
		| 'arrowPrev'
		| 'icon'
		| 'iconOnly'
		| 'hiddeTextMobile'
		| 'fadeIn'
		| 'fullWidth'
		| 'transparent'
		| 'fit'
	)[];
	children?: ReactNode;
	image?: string;
	size?: 'sm' | 'lg';
	tooltip?: string;
	tooltipProps?: Partial<TooltipProps>;
	disabled?: boolean;
	boxProps?: BoxProps;
}

interface ButtonProps {
	isLink?: never;
	to?: never;
	buttonProps?: ButtonHTMLAttributes<HTMLButtonElement> & UnstyledButtonProps;
}

interface LinkProps {
	isLink: true;
	to: string;
	buttonProps?: AnchorHTMLAttributes<HTMLAnchorElement> & AnchorProps;
}

type Props = BaseProps & (ButtonProps | LinkProps);

export const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
	const isLink = checkIfLink(props);

	const className = classNames(
		styles.button,
		...props.themes.map((style) => styles[style]),
		props.buttonProps?.className,
		{
			[styles.small]: props.size === 'sm',
			[styles.disabled]: !!props.disabled,
		}
	);

	return (
		<Tooltip
			label={props.tooltip}
			disabled={!props.tooltip}
			{...props.tooltipProps}
		>
			<Box
				{...props.boxProps}
				className={classNames(styles.wrapper, props.boxProps?.className)}
				w={props.themes.includes('fullWidth') ? '100%' : undefined}
			>
				{isLink ? (
					<Anchor
						component={Link}
						{...props.buttonProps}
						to={props.to}
						className={className}
					>
						{props.themes.includes('arrowPrev') && <ArrowPrevIcon />}
						{props.children}
						{props.themes.includes('arrowNext') && <ArrowNextIcon />}
					</Anchor>
				) : (
					<UnstyledButton
						ref={ref}
						{...props.buttonProps}
						className={className}
					>
						{props.children}
						{props.themes.includes('arrowNext') && <ArrowNextIcon />}
					</UnstyledButton>
				)}
			</Box>
		</Tooltip>
	);
});

function checkIfLink(props: ButtonProps | LinkProps): props is LinkProps {
	return !!props.isLink;
}
