import { UUID } from '@/types/types';
import { QueryKey } from '@/configs/queryKeys';
import { Location } from './LocationApi';
import { User } from '@/types/user';
import EntityApi from './EntityApi';

export enum FlightNotificationPreference {
	USER_VOUCHER_PERMITTED = 'USER_VOUCHER_PERMITTED',
	ALL = 'ALL',
}

export enum FlightNotificationDateType {
	ALL,
	WEEKENDS,
	MORNINGS,
	SELECTED,
}

export interface FlightNotification {
	id: UUID;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	locations: Location[];
	sendSms: boolean;
	sendEmail: boolean;
	selectedDates: string[];
	dateType: FlightNotificationDateType;
	user?: User;
}

class FlightNotificationApi extends EntityApi<FlightNotification> {
	constructor() {
		super(QueryKey.flightNotifications);
	}
}

export default new FlightNotificationApi();
