import React, { useContext, ReactNode } from 'react';
import { StringSignature } from '@/types/types';
import { Content } from '@/api/ContentApi';
import 'moment/dist/locale/pl';

export type AppContents = StringSignature<Content>;

interface Context {}

const ContentContext = React.createContext<Context>(null!);

export const useContent = () => useContext(ContentContext);

export const ContentProvider = ({ children }: { children: ReactNode }) => {
	return (
		<ContentContext.Provider value={{}}>{children}</ContentContext.Provider>
	);
};
