import { ReactNode, Suspense } from 'react';
import { Provider } from 'react-redux';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { store } from '@/store/index';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from './MantineProvider';
import { QueryProvider } from './QueryProvider';
import { AuthProvider } from './AuthProvider';
import { ContentProvider } from './ContentProvider';
import { DatesProvider } from './DatesProvider';
import { Loading } from '@/components/partials/loading/Loading';

export const AppProviders = ({ children }: { children: ReactNode }) => {
	return (
		<Provider store={store}>
			<PayPalScriptProvider
				options={{
					clientId: import.meta.env.VITE_PAYPAL_CLIENT_ID,
					buyerCountry: 'PL',
					locale: 'pl_PL',
					currency: 'PLN',
					enableFunding: ['paypal'],
					disableFunding: ['card'],
				}}
			>
				<BrowserRouter>
					<MantineProvider>
						<QueryProvider>
							<AuthProvider>
								<ContentProvider>
									<DatesProvider>
										<Suspense fallback={<Loading />}>{children}</Suspense>
									</DatesProvider>
								</ContentProvider>
							</AuthProvider>
						</QueryProvider>
					</MantineProvider>
				</BrowserRouter>
			</PayPalScriptProvider>
		</Provider>
	);
};
