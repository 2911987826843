import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/index';
import { StoreKey } from '@/configs/storeKeys';

interface SliceState {
	loading: boolean;
	errorCode: string;
}

const initialState: SliceState = {
	loading: false,
	errorCode: '',
};

export const appSlice = createSlice({
	name: StoreKey.APP,
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.errorCode = action.payload;
		},
	},
});

export const { setLoading, setErrorCode } = appSlice.actions;

export const selectAppState = (state: RootState) => state[StoreKey.APP];
export default appSlice.reducer;
