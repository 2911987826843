import { Attachment, UUID } from '@/types/types';
import { QueryKey } from '@/configs/queryKeys';
import { VoucherCategory } from './VoucherCategoryApi';
import EntityApi from './EntityApi';

export type VoucherPermissionPriority = 1 | 2 | 3 | 4;

export enum VoucherPermission {
	weekdayMorning = 'weekdayMorning',
	weekdayAfternoon = 'weekdayAfternoon',
	weekend = 'weekend',
}

export interface Voucher {
	id: UUID;
	name: string;
	description: string;
	expirationDays: number;
	permissions: Record<
		VoucherPermission,
		{
			includes: boolean;
			priority: VoucherPermissionPriority;
		}
	>;
	voucherCategory: VoucherCategory;
	image?: Attachment;
}

class VoucherApi extends EntityApi<Voucher> {
	constructor() {
		super(QueryKey.vouchers);
	}
}

export default new VoucherApi();
