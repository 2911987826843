import { em } from '@mantine/core';

const APP_CONFIG = {
	QUERY_STALE_TIME: 30_000,
	QUERY_CACHE_TIME: 1_800_000,
	QUERY_RETRY: 2,
	EDIT_LOGOUT_TIME: 6,
	SIZE_PER_PAGE: 6,
	TEXTAREA_LIMIT: 200,
	CONTENT_MAX_WIDTH: 1202,
	INFINITE_SCROLL_PER_PAGE: 3,
	TABLE_PAGE_SIZES: [10, 25, 50],
	MODAL_WIDTH: 528,
	MAX_LOCATIONS_LENGTH: 6,
	MAX_PASSENGERS_AMOUNT: 12,
	PASSANGER_MIN_AGE: 4,
	PASSANGER_MIN_WEIGHT: 25,
	PASSANGER_MAX_WEIGHT: 150,
	Z_INDEX: {
		OVERLAY: 50,
		NOTIFICATION: 100,
		DRAWER: 104,
		HEADER: 105,
		MODAL: 301,
		POPOVER: 301,
		SCROLL_TOP: 50,
		SELECT: 302,
		TOOLTIP: 302,
	},
	ROOT_FONT_SIZE: 16,
	BREAKPOINTS: {
		XS: em(576),
		SM: em(768),
		MD: em(992),
		LG: em(1200),
		XL: em(1408),
	},
	APP_LANGUAGE: 'pl',
	// calc page height without padding, logo, and address
	NAV_HEIGHT: 'calc(100dvh - 48px - 83px - 137px)',
	PAYPAL_BTN_STYLE: {
		shape: 'rect',
		color: 'blue',
		height: 48,
	},
	MAIN_FLIGHT_DESCRIPTION:
		'Lot główny dochodzi do skutku, jeśli warunki pogodowe na to pozwolą, a liczba pasażerów będzie wystarczająca, aby balon mógł wystartować.',
	RESERVE_FLIGHT_DESCRIPTION:
		'Lot rezerwowy dochodzi do skutku gdy na lokalizacji lotu pierwszego wyboru nie ma odpowiednich warunków meteorologicznych.',
	FLIGHT_ALLOWED_HOURS_FOR_SIGN_OUT: 72,
} as const;

export default APP_CONFIG;
