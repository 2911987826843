import { ReactNode } from 'react';
import { DatesProvider as MantineDatesProvider } from '@mantine/dates';
import APP_CONFIG from '@/configs/appConfig';
import 'dayjs/locale/pl';

export const DatesProvider = ({ children }: { children: ReactNode }) => {
	return (
		<MantineDatesProvider
			settings={{
				locale: APP_CONFIG.APP_LANGUAGE,
				firstDayOfWeek: 1,
				weekendDays: [0],
				timezone: 'Europe/Warsaw',
			}}
		>
			{children}
		</MantineDatesProvider>
	);
};
