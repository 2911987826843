import { createRoot } from 'react-dom/client';
import { AppProviders } from '@/providers/AppProviders';
import { App } from '@/components/routes/App';
import 'react-18-image-lightbox/style.css';
import './sass/global.scss';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<AppProviders>
		<App />
	</AppProviders>
);
