export enum AppPaths {
	EMAIL_CONFIRMATION = 'email-confirmation/:token',

	LOGIN = 'login',
	RESET_PASSWORD = 'reset-password',
	SET_PASSWORD = 'set-password',

	PRODUCTS_CATEGORIES = 'products-categories',
	PRODUCTS = 'products',

	USER_VOUCHERS = 'user-vouchers',
	USER_VOUCHERS_SUCCESS = 'user-vouchers-success',

	VOUCHERS = 'vouchers',
	PASSANGERS = 'passengers',

	FLIGHTS = 'flights',
	FLIGHT_PASSENGERS = 'flight-passengers',
	FLIGHT_NOTIFICATIONS = 'flight-notifications',

	LOCATIONS = 'locations',

	ACCOUNT = 'account',
	CHANGE_PASSWORD = 'change-password',
	CHANGE_MAIL = 'change-mail',

	INFO = 'info',
	TERMS = 'terms',
	CONTRADICTIONS = 'contradictions',

	ACCESSORIES = 'accessories',
	CART = 'cart',
	PAYMENT_RESULT = 'payment-result',

	ORDERS = 'orders',
}
