import { ApiError } from '@/types/types';
import { ErrorCode } from '@/configs/errorCode';
import { getErrorMessage } from '@/utils/utilities';
import axios, { AxiosError, HttpStatusCode, isAxiosError } from 'axios';
import APP_CONFIG from '@/configs/appConfig';

const httpClient = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
	headers: {
		Accept: 'application/json',
		'Content-type': 'application/json; charset=UTF-8',
		'Accept-Language': APP_CONFIG.APP_LANGUAGE,
	},
});

httpClient.interceptors.request.use((request) => {
	if (request.data instanceof FormData) request.headers.setContentType('');
	return request;
});

httpClient.interceptors.response.use(
	(response) => response,
	(error) => {
		if (isAxiosError<ApiError>(error)) {
			if (error.code === AxiosError.ECONNABORTED) throw error;

			const stack = Array.isArray(error.response!.data.message)
				? error.response!.data.message
				: [error.response!.data.message];

			throw {
				status: error.response!.data.status,
				stack,
				message: getErrorMessage(stack),
			};
		}

		throw {
			code: HttpStatusCode.InternalServerError,
			stack: [ErrorCode.GENERIC],
			message: getErrorMessage(ErrorCode.GENERIC),
		};
	}
);

export default httpClient;
